body{
  background-color: rgb(221, 221, 221);
}
.App {
  max-width: 1000px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid blue;
  position: relative;
}

.header h1{
  text-align: center;
}

.header nav ul{
  display: flex;

}

.header nav ul li{
  list-style: none;
  margin-right: 30px;
  cursor: pointer;
}


.product-container{
  width: 100%;
}

.product-container table{
  width: 90%;
  margin: 100px auto;
  border-collapse: collapse;
}

.product-container table tr td,.product-container table tr th{
  border-bottom: 1px solid rgb(187, 187, 187);
  
}

.table-ctr {
  text-align: center;
}


.product-form-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: aliceblue;
  margin: 2em auto;
  padding: 1em 2em 2em 2em;
  width: 90%;
}

.product-form-container label{
  min-width: 150px;
  display: inline-block;
}
.product-form-container input{
  display: inline-block;
  width: 300px;
}

.close-btn{
  position: absolute;
  top:0;
  right: 0;
}